body {
    margin: 0;
    background: var(--svart) !important;
    color: var(--hvit) !important;
}

:root {
    --soloppgang: #ffb88d;
    --soloppgang-kontrast: #ff8034;

    --skyfritt: #b1e8ff;
    --skyfritt-kontrast: #43cbff;

    --solnedgang: #ff9999;
    --solnedgang-kontrast: #ff5b5b;

    --kveld: #e5b1ff;
    --kveld-kontrast: #8e24c9;

    --natt: #6d7abb;
    --natt-kontrast: #162365;

    --regn: #bccedd;
    --regn-kontrast: #7e9cb9;

    --overskyet: #e7e7e7;
    --overskyet-kontrast: #cecece;

    --sol: #fff2ad;
    --sol-kontrast: #fff02b;

    --gronn: #a1f5e3;
    --gronn-kontrast: #16dbc4;

    --svart: #0e0e0e;
    --hvit: #ffffff;
}

/* TEKST */

h1 {
    font-size: 100px !important;
    font-family: 'DINOT-Medium' !important;
    color: var(--sol-kontrast);
}

h1.underoverskrift {
    font-size: 65px !important;
    font-family: 'NewZaldBook' !important;
    margin-bottom: 0 !important;
}

h2 {
    font-size: 55px !important;
    font-family: 'DINOT-Medium' !important;
    color: var(--sol-kontrast);
}

h3 {
    font-size: 40px !important;
    font-family: 'NewZaldBook' !important;
}

h4 {
    font-size: 30px !important;
    font-weight: 400 !important;
    font-family: 'NewZaldBook' !important;
}

h5 {
    font-size: 30px !important;
    font-family: 'NewZaldBook' !important;
}

h6 {
    font-size: 40px !important;
    font-family: 'NewZaldBook' !important;
}

.b1 {
    font-size: 1rem !important;
    font-weight: 350 !important;
    font-family: 'DINOT-Light' !important;
}

.b-uthevet {
    font-size: 20px !important;
    font-weight: 350 !important;
    font-family: 'DINOT' !important;
}

.b2 {
    font-size: 14px !important;
    line-height: 20px !important;
    font-family: 'DINOT-Light' !important;
}

.navbar-tekst {
    font-size: 20px !important;
    font-family: 'DINOT-Light' !important;
}
.navbar-tekst-valgt {
    font-size: 20px !important;
    font-family: 'DINOT-Medium' !important;
}

@media only screen and (max-width: 768px) {
    h1 {
        font-size: 40px !important;
    }
    h2 {
        font-size: 30px !important;
    }
    h3 {
        font-size: 28px !important;
    }
    h4 {
        font-size: 20px !important;
    }
    h5 {
        font-size: 18px !important;
    }
}

/* FONT */

@font-face {
    font-family: 'DINOT';
    src: local('DINOT'), url('./fonts/DINOT.ttf') format('truetype');
}

@font-face {
    font-family: 'DINOT-Light';
    src: local('DINOT-Light'), url('./fonts/DINOT-Light.ttf') format('truetype');
}

@font-face {
    font-family: 'DINOT-LightItalic';
    src: local('DINOT-LightItalic'), url('./fonts/DINOT-LightItalic.ttf') format('truetype');
}

@font-face {
    font-family: 'DINOT-Medium';
    src: local('DINOT-Medium'), url('./fonts/DINOT-Medium.ttf') format('truetype');
}

@font-face {
    font-family: 'NewZaldBook';
    src: url('./fonts/Newzald-Book.otf');
    src: url('./fonts/Newzald-Book.otf?#iefix') format('truetype');
}
